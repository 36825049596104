import FeaturesMixin from "./features.mixin";

export const SANCTION_SEVERITY_HIGH = 1;
export const SANCTION_SEVERITY_VERY_HIGH = 2;

const SanctionsMixin = {
    methods: {
        shouldShowSanctionMessage(severity) {
          return severity === SANCTION_SEVERITY_HIGH || severity === SANCTION_SEVERITY_VERY_HIGH;
        },
        getSanctionMessage(severity) {
            if (severity === SANCTION_SEVERITY_HIGH) {
                return 'SANCTIONS APPLY.  LEGAL MUST APPROVE PRIOR TO SUBMISSION<br />' 
                    + this.getAdditionalSanctionMessageForclient(severity);
            } else if (severity === SANCTION_SEVERITY_VERY_HIGH) {
                return 'THIS COUNTRY IS CURRENTLY SANCTIONED AND PLACEMENTS IN THIS COUNTRY ARE RESTRICTED';
            }
            return null;
        },
        getAdditionalSanctionMessageForclient(severity) {
            if (FeaturesMixin.methods.isClient(process.env.VUE_APP_ICEDE_CLIENT_SOMPO)) {
                if (severity === SANCTION_SEVERITY_HIGH) {
                    return "** UNDERWRITERS ARE RESPONSIBLE FOR PROVIDING PROOF OF LEGAL APPROVAL **";
                }
            }
            return '';
        }

    }
}

export default SanctionsMixin;
