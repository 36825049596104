<template>
  <div class="container text-center">
    <h3>OTP Login</h3>
    <h5 class="text-muted">Login to save and track your Inquiries</h5>
    <form @submit.prevent="handleOTPLogin">
      <div class="row justify-content-center">
        <div class="col-3">
          <div v-if="!$store.getters.emailSent" class="form-group">
            <label for="email">Email Address</label>
            <input type="email" class="form-control" name="email" id="email" @change="event => $store.commit('setOTPLoginEmail', { email: event.target.value})" placeholder="Enter your email address" />
          </div>
          <div v-else class="form-group">
            <label for="otp">One Time Password</label>
            <input type="text" class="form-control" name="otp" id="otp" v-model="otp" placeholder="Enter OTP from email" />
          </div>
          <span class="text-danger">{{ requestFeedback }}</span>
          <br />
          <button type="submit" class="btn btn-primary" :disabled="requestSending">
            <span :class="{'hidden': requestSending || $store.getters.emailSent}"><i class="fa fa-paper-plane"></i>&nbsp;Send</span>
            <span :class="{'hidden': !requestSending}"><i class="fa fa-spinner fa-spin"></i>&nbsp;Send</span>
            <span :class="{'hidden': requestSending || !$store.getters.emailSent}"><i class="fa fa-door-open"></i>&nbsp;Login</span>
          </button>
          <br />
        </div>
      </div>
    </form>
    <button type="link" v-if="$store.getters.emailSent" class="btn btn-link" @click="() => $store.commit('setEmailSent', { sent: false })">
      <i class="fa fa-undo"></i>&nbsp;Reset
    </button>
  </div>
</template>

<script>
import AuthService from '@/services/auth.service';

export default {
  name: 'OTPLogin',
  data: () => ({
    requestSending: false,
    requestFeedback: '',
    otp: '',
  }),
  methods: {
    requestOTPEmail() {
      this.requestFeedback = '';
      if (this.email === '') {
        this.requestFeedback = 'Please enter your email address';
        console.log('No email address entered');
        return;
      }
      console.log('Sending OTP email request to server');
      this.requestSending = true;
      this.$store.commit('setEmailSent', { sent: true });
      AuthService.sendOTPEmailRequest(this.$store.getters.otpLoginEmail)
        .then(() => {
          console.log('OTP email sent successfully');
        })
        .catch((error) => {
          console.log('Error sending OTP email', error);
          // TODO: chage this from raw error message
          this.requestFeedback = error.message;
          this.$store.commit('setEmailSent', { sent: false });
        }).finally(() => {
          this.requestSending = false;
        })
    },
    sendOTPLoginRequest() {
      this.requestFeedback = '';
      console.log('Sending OTP login request to server');
      this.requestSending = true;
      AuthService.loginWithOTP(this.$store.getters.otpLoginEmail, this.otp)
        .then((data) => {
          if (data.success) {
            this.$store.commit("setUser", { user: data.user });
          } else {
            this.requestFeedback = "OTP Incorrect";
          }
        })
        .catch((error) => {
          console.log('Error logging in with OTP', error);
          // TODO: Change this from raw error message
          this.requestFeedback = error.message;
        }).finally(() => {
          this.requestSending = false;
        });
    },
    handleOTPLogin() {
      if (this.$store.getters.emailSent) {
        this.sendOTPLoginRequest();
      } else {
        this.requestOTPEmail();
      }
    }
  }
}
</script>

<style>

</style>
