import axios from "axios";
import store from "@/store";
import * as Sentry from '@sentry/vue';
import ApiService from "@/services/api.service";
import FeaturesMixin from "@/mixins/features.mixin";

const AuthService = {
    async loginFirstTimeOrEmailLink(token) {
        let url = new URL(process.env.VUE_APP_INQUIRY_API_ENDPOINT + "/loginWithToken");

        let { data } = await ApiService.post(url, { token });

        return data;
    },
    async doLoginWithToken(token, email, po) {
        let url = new URL(process.env.VUE_APP_INQUIRY_API_ENDPOINT + "/verifyToken");

        if (process.env.VUE_APP_USING_COMPLEX_KEY_AUTH === "1" 
          && token === process.env.VUE_APP_AUTH_COMPLEX_KEY
        ) {
          url = new URL(process.env.VUE_APP_INQUIRY_API_ENDPOINT + "/loginWithEmail");
          url.searchParams.set('token', token);
        }

        if (email) {
          url.searchParams.set('email', email);
        }

        if (po) {
          url.searchParams.set('po', po);
        }

        let { data } = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`
          },
          withCredentials: true
        });
        return data;
    },
    async loginWithToken(token, email, po) {
          store.commit("setTokenExpired", { expired: false });
          store.commit("setVerifyingToken", { verifying: true });
          let loginMethod = this.doLoginWithToken;
          try {
            const url = process.env.VUE_APP_API_ENDPOINT + "/csrf-cookie";
  
            console.log("Sending CSRF request");
            await axios
              .get(url, {
                withCredentials: true
            })

            if (FeaturesMixin.methods.usingLoginFirstTimeOrEmailLink()) {
              loginMethod = this.loginFirstTimeOrEmailLink;
            }

            let data = await loginMethod(token, email, po);

            store.commit("setUser", { user: data.user });
            store.commit("setVerifyingToken", { verifying: false });
            store.commit("setEmailSent", { sent: false });
        } catch (err) {
            console.error(err);
            Sentry.captureException(err);
            store.commit("setVerifyingToken", { verifying: false });
            store.commit("setTokenExpired", { expired: true });
            store.dispatch("resendEmail", { token });
            return false;
        }
    },
    async sendOTPEmailRequest(email) {
        let url = new URL(process.env.VUE_APP_INQUIRY_API_ENDPOINT + "/sendOTPEmail/" + email);
        let { data } = await ApiService.get(url);
        return data;
    },
    async loginWithOTP(email, otp) {
        store.commit("setVerifyingToken", { verifying: true });
        let url = new URL(process.env.VUE_APP_INQUIRY_API_ENDPOINT + "/loginWithOTP");
        let { data } = await ApiService.post(url, { otp, email });
        store.commit("setVerifyingToken", { verifying: false });
        return data;
    },
    async loginSSO() {
        let url = new URL(process.env.VUE_APP_INQUIRY_API_ENDPOINT + "/loginSSO");
        let { data } = await ApiService.get(url);
        store.commit("setUser", { user: data.user });
        return data;
    },
}

export default AuthService;
