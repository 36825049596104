import Vue from "vue";
import router from "./router";

import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

let bSentryActive = false;

// Sentry - Error logging
if (
  process.env.VUE_APP_INQUIRY_API_ENDPOINT !== undefined &&
  process.env.VUE_APP_INQUIRY_API_ENDPOINT !== "" &&
  process.env.VUE_APP_SENTRY_DSN !== undefined &&
  process.env.VUE_APP_SENTRY_DSN !== ""
) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    logErrors: true,
    environment: process.env.VUE_APP_SENTRY_ENV,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [process.env.VUE_APP_INQUIRY_API_ENDPOINT, /^\//]
      })
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  });
  bSentryActive = true;
}

import BootstrapVue from "bootstrap-vue/dist/bootstrap-vue.esm";
import SideBar from "./components/SidebarPlugin";
// import GlobalComponents from "./globalComponents";
import GlobalDirectives from "./globalDirectives";

// These Vue Axios bits may not be needed - Using pure axios imported in Vue files currently...
// import Axios from "axios";
// import VueAxios from "vue-axios";
import vSelect from "vue-select";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";

// Global CSS
import "./assets/css/main.css";
import "./assets/sass/main.scss";

// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

// Light Bootstrap Dashboard theme styles
import "./assets/sass/light-bootstrap-dashboard.scss";
// Light Bootstrap Dashboard sidebar plugin
Vue.use(SideBar);
Vue.use(GlobalDirectives);
// Vue.use(GlobalComponents);

// vue-select
import "vue-select/dist/vue-select.css";
Vue.component("v-select", vSelect);

// vuejs-datepicker
import Datepicker from "vuejs-datepicker";
Vue.component("date-picker", Datepicker);

// vue-toasted
import Toasted from "vue-toasted";
Vue.use(Toasted, {
  duration: 8000,
  keepOnHover: true,
  iconPack: "fontawesome",
  action: {
    icon: "window-close",
    class: "toast-close-button",
    onClick: (_, toastObject) => {
      toastObject.goAway(0);
    }
  }
});

// Vue.use(VueAxios, Axios);
Vue.use(BootstrapVue);

Vue.config.productionTip = false;

// TODO: Move filters and mixins into their own files - will make testing easier
// Currency format filter
Vue.filter("formatCurrency", function(
  value,
  significantDigits,
  fractionalDigits = 2
) {
  if (isNaN(value)) {
    return value;
  } else {
    const options = { maximumFractionDigits: fractionalDigits };
    if (significantDigits) {
      options.maximumSignificantDigits = significantDigits;
    }
    return new Intl.NumberFormat("en-gb", options).format(value);
  }
});

Vue.filter("numsBeforeDecimal", function(number) {
  if (Number.isInteger(number)) {
    return number.toString().length;
  }

  return number.toString().split(".")[0].length;
});

import store from "./store";

if (bSentryActive) {
  if (store.getters.isLoggedIn && store.getters.user) {
    const user = store.getters.user;
    Sentry.setUser({
      id: user.client_broker_contact_id,
      email: user.email,
      ip: "{{auto}}"
    });
  } else {
    Sentry.setUser(null);
  }
}

// Parse URL from domain
const domain = window.location.host;
if (domain === process.env.VUE_APP_DOMAIN_MAPPED_URL_A) {
  DefaultsService.selectProducingOffice(process.env.VUE_APP_DOMAIN_MAPPED_URL_A_POID, true);
} else if (domain === process.env.VUE_APP_DOMAIN_MAPPED_URL_B) {
  DefaultsService.selectProducingOffice(process.env.VUE_APP_DOMAIN_MAPPED_URL_B_POID, true);
}

Vue.mixin({
  methods: {
    clientImgSrc(imagePath) {
      let imageDir = store.getters.icede_sub_client != null ? 
        store.getters.icede_sub_client.toLowerCase() 
        : process.env.VUE_APP_ICEDE_CLIENT.toLowerCase();
      let clientImagePath = `@/assets/img/${imageDir}/${imagePath}`;
      let image;
      try {
        // This String literal is intentionally declared twice because require gets resolved at compile-time.
        image = require(`@/assets/img/${imageDir}/${imagePath}`);
      } catch (e) {
        if (
          process.env.VUE_APP_ICEDE_CLIENT !==
          process.env.VUE_APP_ICEDE_CLIENT_DEFAULT
        ) {
          console.error(
            `An error occurred while trying to require the client image at the path ${clientImagePath}: `,
            e
          );
        }
      }
      if (image === undefined) {
        // Fallback to placeholder iCede logo
        image = require("@/assets/img/logo.png");
      }
      return image;
    }
  }
});

function startWithComponent(component) {
  new Vue({
    router,
    store,
    render: h => h(component)
  }).$mount("#app");
}

// Dev log for debugging
Vue.prototype.$devLog = function(...args) {
  if (process.env.NODE_ENV !== "production") {
    // add DEV: to the start of the log message
    args[0] = "DEV ONLY: " + args[0];
    console.log.apply(console, args);
  }
}

// Start the app with a different base component depending on env
if (process.env.VUE_APP_SIDEBAR_ACTIVE === "1") {
  import("./SideBarApp.vue")
    .then(module => {
      startWithComponent(module.default);
    })
    .catch(err => {
      console.error("Could not find the SideBarApp base component...");
      console.error(err);
    });
} else {
  import("./App.vue")
    .then(module => {
      startWithComponent(module.default);
    })
    .catch(err => {
      console.error("Could not find the App base component...");
      console.error(err);
    });
}

// API Service
import ApiService from './services/api.service';
import DefaultsService from "./services/defaults.service";
ApiService.init(process.env.VUE_APP_INQUIRY_API_ENDPOINT);
