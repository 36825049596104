const DateService = {
  nowPrettyFormatted() {
    return this.prettyFormat(new Date());
  },
  prettyFormat(date) {
    const options = {
      day: 'numeric', 
      month: 'long', 
      year: 'numeric', 
      hour: '2-digit', 
      minute: '2-digit', 
      second: '2-digit'
    };
    return date.toLocaleString("en-GB", options);
  }
}

export default DateService;
