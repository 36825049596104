<template>
  <b-modal id="modal-login" hide-footer ref="modal-login">
    <template v-slot:modal-header>
      <h2 class="my-0"><i class="fa fa-unlock"></i>&nbsp;Login</h2>
    </template>
    <h4 class="my-0 mb-3">{{ subtitle }}</h4>
    <div class="form-group">
      <label for="inpEmail">Your Email Address</label>
      <input
        id="inpEmail"
        autocomplete="off"
        class="form-control"
        placeholder="Email"
        type="text"
        v-model="internalEmail"
      />
    </div>
    <div class="row">
      <div class="col">
        <div class="form-group form-check-inline" style="width: 200px">
          <input
            class="form-control form-check-input"
            type="checkbox"
            id="chkRememberMe"
            style="width: initial;"
            v-model="rememberMe"
          />
          <label
            class="form-check-label"
            for="chkRememberMe"
            style="width:150px;"
            >Remember Me?</label
          >
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group col">
        <label for="inpForename"
          >Your Forename&nbsp;<em class="text-muted">(optional)</em></label
        >
        <input
          id="inpForename"
          autocomplete="off"
          class="form-control"
          placeholder="Forename"
          type="text"
          v-model="forename"
        />
      </div>
      <div class="form-group col">
        <label for="inpSurname"
          >Your Surname&nbsp;<em class="text-muted">(optional)</em></label
        >
        <input
          id="inpSurname"
          autocomplete="off"
          class="form-control"
          placeholder="Surname"
          type="text"
          v-model="surname"
        />
      </div>
    </div>
    <small v-if="usingLoginFirstTimeOrEmailLink()"><em>If you are logging in for the first time, you will be authenticated straight away. On subsequent login attempts, you will be sent a login link via email.</em></small>
    <br v-if="usingLoginFirstTimeOrEmailLink()" />
    <div v-if="loggingIn" class="row border-top">
      <div class="col text-center py-3">
        <span> <i class="fa fa-sync fa-spin"></i>&nbsp;Logging In... </span>
      </div>
    </div>
    <div v-else class="row border-top pt-3">
      <div class="col">
        <button class="btn btn-secondary btn-block" @click="handleCancel">
          Cancel
        </button>
      </div>
      <div class="col">
        <button
          class="btn btn-primary btn-block btn-fill"
          @click="handleLogin"
          :disabled="internalEmail.length === 0"
        >
          {{ loginButtonText }}
        </button>
      </div>
    </div>
  </b-modal>
</template>

<style scoped></style>

<script>
import axios from "axios";
import FeaturesMixin from "@/mixins/features.mixin";

const LOCAL_STORAGE_USER_KEY = "icede_inquiry_tool_user";

export default {
  name: "LoginModal",
  props: {
    email: {
      type: String,
      default: ""
    },
    loginButtonText: {
      type: String,
      default: "Login"
    },
    subtitle: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      internalEmail: "",
      forename: "",
      surname: "",
      fromModal: false,
      loggingIn: false,
      rememberMe: false
    };
  },
  mixins: [FeaturesMixin],
  methods: {
    async handleLogin() {
      const url = process.env.VUE_APP_API_ENDPOINT + "/csrf-cookie";

      this.fromModal = true;
      this.loggingIn = true;

      if (this.rememberMe) {
        window.localStorage.setItem(LOCAL_STORAGE_USER_KEY, this.internalEmail);
      } else {
        window.localStorage.removeItem(LOCAL_STORAGE_USER_KEY);
      }

      let d = this;
      axios
        .get(url, {
          withCredentials: true
        })
        .then(function() {
          d.login();
        })
        .catch(function(error) {
          if (error.response) {
            const data = error.response.data.errors;
            console.log(data);
          }
        });
    },
    // Todo: Move this to a service and use to do token login for Sompo!!!!!
    login() {
      const expectLoginEmail = this.usingLoginFirstTimeOrEmailLink();
      const url = process.env.VUE_APP_INQUIRY_API_ENDPOINT 
        + (expectLoginEmail ? "/sendLoginEmailOrLoginFirstTime" : "/loginWithEmail");
      const context = this;
      axios
        .post(
          url,
          {
            email: this.internalEmail,
            forename: this.forename,
            surname: this.surname
          },
          {
            withCredentials: true
          }
        )
        .then(function(response) {
          if (response.data.user) {
            // Set the returned user as the logged in user
            context.$store.commit("setUser", { user: response.data.user });
            context.$toasted.success("Logged in");
            context.$bvModal.hide("modal-login");
            context.$emit("logged-in");
          } else if (expectLoginEmail) {
            context.$toasted.success("Login email sent!");
            context.$bvModal.hide("modal-login");
          } else {
            context.$toasted.error("An error occurred while attempting to log in, please try again later");
          }
          context.loggingIn = false;
        })
        .catch(function(error) {
          if (error.response) {
            const data = error.response.data.errors;
            console.error(data);
          }
        });
    },
    handleCancel() {
      // Hide the modal...
      this.$refs["modal-login"].hide();
    }
  },
  mounted() {
    this.internalEmail = this.email;
    if (this.internalEmail === null || this.internalEmail.length === 0) {
      this.internalEmail = window.localStorage.getItem(LOCAL_STORAGE_USER_KEY);
    }
    if (this.internalEmail !== null) {
      this.rememberMe = true;
    } else {
      this.internalEmail = "";
    }
  }
};
</script>
