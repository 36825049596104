<template>
  <td class="wiki-td" 
    :class="{
        'text-center': !countryField,
        [variant]: true,
      }"
      @mouseenter="() => toggleAdditionalInfo(true)" 
      @mouseleave="() => toggleAdditionalInfo(false)" 
    >
    <div :class="{'wiki-additional': selected}"
      v-b-tooltip="{
        title:
          'Additional Commentary: ' + wikiData.additional,
        trigger: 'hover',
        variant: (wikiData.level === 'success' ? 'success' : (wikiData.level === 'warning' ? 'warning' : 'info')),
        disabled: !selected || !wikiData.additional || !useAdditionalTooltip,
      }"
    >
    <span v-if="wikiData.value && wikiData.value.length !== 0">{{
      wikiData.value | formatCurrency(sigFigs)
    }}</span>
    <span v-else><small> - </small></span>
    <br v-if="wikiData.additional" />
    <button v-if="wikiData.additional" 
            class="btn btn-sm btn-secondary btn-additional"
            :class="{'print-styles': printStyles}"
            @click="() => locked = !locked"
            >
      <i class="fa fa-info"></i>
    </button>
    <text-truncated
      v-if="false"
      :text="
        wikiData.additional
          ? $options.filters.formatCurrency(wikiData.additional, sigFigsAdditional)
          : ''
      "
      :max-length="30"
      element="small"
    ></text-truncated>
    </div>
  </td>
</template>

<script>
import TextTruncated from "./TextTruncated";
export default {
  name: "WikiTableData",
  components: {
    TextTruncated
  },
  props: {
    wikiData: Object,
    countryField: {
      type: Boolean,
      default: false
    },
    countryId: {
      type: Number,
      default: 0,
    },
    printStyles: {
      type: Boolean,
      default: false,
    },
    useAdditionalTooltip: {
      type: Boolean,
      default: false,
    },
    sanctionSeverity: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    locked: false,
  }),
  computed: {
    sigFigs() {
      return this.$options.filters.numsBeforeDecimal(this.wikiData.value);
    },
    sigFigsAdditional() {
      return this.$options.filters.numsBeforeDecimal(this.wikiData.additional);
    },
    selected() { // This should probably be converted to a prop
      const context = this;
      const localPolicy = this.$store.getters.localPolicies.find(
        policy => policy.options.country.country_id == context.countryId
      );
      if (!localPolicy) {
        return;
      }
      return localPolicy.selectedWikiItem?.id == this.wikiData.id;
    },
    variant() {
      if (this.sanctionSeverity === 2) {
        return 'capabilities-danger';
      }
      if (this.sanctionSeverity === 1 && this.countryField) {
        return 'capabilities-warning';
      }
      if(this.wikiData.level === 'success') {
        return 'capabilities-success';
      }
      if(this.wikiData.level === 'warning') {
        return 'capabilities-warning';
      }
      if(this.wikiData.level === 'danger') {
        return 'capabilities-danger';
      }
      return 'no-variant';
    }
  },
  methods: {
    toggleAdditionalInfo(show) {
      if (!this.wikiData.additional || (!show && this.locked)) {
        return;
      }
      if (show && this.locked) {
        this.locked = false;
      }
      let wikiItem = null;
      if (show) {
        wikiItem = this.wikiData;
      }
      this.$store.dispatch("setPolicySelectedWikiItem", { countryId: this.countryId, wikiItem });
    },
  }
};
</script>
<style scoped>
button.btn.btn-sm.btn-additional {
  font-size: 0.6em;
  border-radius: 50%;
  padding: 0 5px;
}
button.btn-additional.print-styles {
  display: none;
}
td {
  padding: 0 !important;
}
td div {
  width: 100%;
  min-width: 100%;
  min-height: 100%;
}
</style>
